import EventEmitter from 'events';
import { produce } from 'immer';
import clamp from 'lodash/clamp';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import { Nullable } from 'src/types/nullable.type';
import { blockGridToBlockList } from '../helpers/block/block-grid-to-block-list';
import { blocksToBlockGrid } from '../helpers/block/blocks-to-block-grid';
import { findBlock } from '../helpers/find-block';
import { getSuperRect } from '../helpers/get-super-rect';
import { findSectionIndexByBlockId } from '../helpers/section/find-section-index-by-block-id';
import { findSectionIndexById } from '../helpers/section/find-section-index-by-id';

import {
  AspectRatio,
  AutoHeightSize,
  Block,
  BlockContent,
  BlockLayoutExtraParams,
  BlockLayoutSpec,
  BlockVerticalAlignment,
  Boundary,
  BoundaryParams,
  BoundarySpace,
  CalculatedHeightSize,
  CreateBlockOperation,
  CreateSectionOperation,
  DeleteBlockOperation,
  DeleteSectionOperation,
  HiddenSectionOperation,
  FolderUpdateOperation,
  FolderSetNameOperation,
  ImageBlockContent,
  MoveSectionOperation,
  Operation,
  Rect,
  Section,
  SectionGrid,
  UpdateBlockContentOperation,
  SetBlockContentUUIDOperation,
  SetBlockLayoutOperation,
  SetBlockWidthOperation,
  SetSectionNameOperation,
  Size,
  StaticSize,
  SetBlockContentOperation,
  BlockContentSizeMap,
  PlaceholderBlockContent,
  FoldersUpdateOperation,
} from '../types';
import { blockGridIndexToBlockListIndex } from './block-grid-index-to-block-list-index';
import { getWidthPercentage } from './get-width-percentage';
import { locateBlockInGrid } from './locate-block-in-grid';

import { createMutualActionPlanBlockLayout } from '../mutual-action-plans/layout';
import { createLinkBlockLayout } from '../links/layout';
import { createFileBlockLayout } from '../files/layout';
import { findBlockById } from '../helpers/block/find-block-by-id';
import { Content } from 'src/common/interfaces/content.interface';
import { createLogoBlockLayout } from '../logo/layout';
import { generateKeyBetween } from 'fractional-indexing';
import { genKeyForPos } from '../helpers/block/gen-key-for-pos';
import { LayoutConfig, LayoutInputs, LayoutOutputs } from './types';
import { layout } from './layout';
import { getMinBlockHeight } from './calculate-row-block-sizes';
import { createContentsListLayout } from '../contents-list/layout';
import { getFriendlySectionPath } from '../helpers/section/get-friendly-section-path';
import { createSignupBlockLayout } from '../neue-player/sign-up/layout';
import { calculateWidth } from './calculate-width';
import { createTableBlockLayout } from '../components/table/layout';
import { createCtaButtonLayout } from '../cta-button/layout';
import { generateBlockId } from '../helpers/generate-block-id';

const { max, min } = Math;

export const DEFAULT_LAYOUT_CONFIG: LayoutConfig = {
  margin: {
    top: 120,
    bottom: 300,
  },
  rowGap: 24,
  columnGap: 24,
  sectionGap: { min: 100, max: 300 },
  sectionDivider: { thickness: 3 },
  emptySectionHeight: 40,
  staticBlockMaxHeight: 500,
  autoHeightBlockMaxHeight: 500,
} as const;

const MAX_COLUMNS = 4;
const NEW_BLOCK_COLUMN_THRESHOLD = 2;

const BOUNDARY_THICKNESS = 4;
const BOUNDARY_HORIZONTAL_OFFSET = 8;
const BOUNDARY_VERTICAL_OFFSET = 8;

const BLOCK_MIN_WIDTH = 100;
const ROW_MIN_HEIGHT = 40;

function getDragPolygonHorizontal({
  isFirst,
  isLast,
  isFirstSection,
  isLastSection,
  rowY,
  previousRowHeight,
  rowHeight,
  inputs,
  outputs,
  layoutConfig,
}: {
  isFirst: boolean;
  isLast: boolean;
  isFirstSection: boolean;
  isLastSection: boolean;
  rowY: number;
  previousRowHeight: number;
  rowHeight: number;
  outputs: LayoutOutputs;
  inputs: EditorLayoutInputs;
  layoutConfig: LayoutConfig;
}): NonNullable<BoundarySpace['dragPolygon']> {
  const SECTION_DIVIDER_MARGIN = Math.max(
    layoutConfig.sectionGap.min / 2,
    Math.min(layoutConfig.sectionGap.max / 2, inputs.innerAreaHeight / 4 / 2)
  );

  const left = -inputs.outerAreaLeftOffset;
  const right = outputs.gridWidth + inputs.outerAreaRightOffset;
  const top =
    isFirstSection && isFirst
      ? 0
      : isFirst
      ? rowY - SECTION_DIVIDER_MARGIN - layoutConfig.sectionDivider.thickness / 2
      : rowY - layoutConfig.rowGap - previousRowHeight / 2;
  const bottom =
    isLastSection && isLast
      ? rowY - layoutConfig.rowGap + outputs.bottomOffset
      : isLast
      ? rowY - layoutConfig.rowGap + SECTION_DIVIDER_MARGIN
      : rowY + rowHeight / 2;

  // console.log('getDragPolygonHorizontal', 'left', left, 'right', right, 'top', top, 'bottom', bottom);
  return [
    [left, top],
    [right, top],
    [right, bottom],
    [left, bottom],
  ];
}

function getDragPolygonVertical({
  isFirst,
  isLast,
  isFirstRow,
  isLastRow,
  isFirstSection,
  isLastSection,
  rowY,
  rowHeight,
  colX,
  colWidth,
  previousColWidth,
  inputs,
  outputs,
  layoutConfig,
}: {
  isFirst: boolean;
  isLast: boolean;
  isFirstRow: boolean;
  isLastRow: boolean;
  isFirstSection: boolean;
  isLastSection: boolean;
  rowY: number;
  rowHeight: number;
  colX: number;
  colWidth: number;
  previousColWidth: number;
  outputs: LayoutOutputs;
  inputs: EditorLayoutInputs;
  layoutConfig: LayoutConfig;
}): NonNullable<BoundarySpace['dragPolygon']> {
  const SECTION_DIVIDER_MARGIN = Math.max(
    layoutConfig.sectionGap.min / 2,
    Math.min(layoutConfig.sectionGap.max / 2, inputs.innerAreaHeight / 4 / 2)
  );

  if (isFirst) {
    const leftOuter = -inputs.outerAreaLeftOffset;
    const topOuter =
      isFirstSection && isFirstRow ? 0 : isFirstRow ? rowY - SECTION_DIVIDER_MARGIN : rowY - layoutConfig.rowGap / 2;
    const leftInner = -layoutConfig.columnGap / 2;
    const topInner = rowY - layoutConfig.rowGap / 2;
    const right = colX + colWidth / 2;
    const verticalCenter = rowY + rowHeight / 2;
    const bottomInner = rowY + rowHeight + layoutConfig.rowGap / 2;
    const bottomOuter = rowY + rowHeight + (isLastRow ? outputs.bottomOffset : layoutConfig.rowGap / 2);
    return [
      [leftOuter, topOuter],
      [leftInner, topInner],
      [right, verticalCenter],
      [leftInner, bottomInner],
      [leftOuter, bottomOuter],
    ];
  }
  if (isLast) {
    const left = colX - layoutConfig.columnGap - previousColWidth / 2;
    const rightInner = colX - layoutConfig.columnGap / 2;
    const rightOuter = colX - layoutConfig.columnGap + inputs.outerAreaRightOffset;
    const topOuter =
      isFirstSection && isFirstRow ? 0 : isFirstRow ? rowY - SECTION_DIVIDER_MARGIN : rowY - layoutConfig.rowGap / 2;
    const topInner = rowY - layoutConfig.rowGap / 2;
    const bottomInner = rowY + rowHeight + layoutConfig.rowGap / 2;
    const bottomOuter = rowY + rowHeight + (isLastRow ? outputs.bottomOffset : layoutConfig.rowGap / 2);
    const verticalCenter = rowY + rowHeight / 2;
    return [
      [left, verticalCenter],
      [rightInner, topInner],
      [rightOuter, topOuter],
      [rightOuter, bottomOuter],
      [rightInner, bottomInner],
    ];
  }
  const left = colX - layoutConfig.columnGap - previousColWidth / 2;
  const right = colX + colWidth / 2;
  const top = rowY - layoutConfig.rowGap / 2;
  const bottom = rowY + rowHeight + layoutConfig.rowGap / 2;
  const verticalCenter = rowY + rowHeight / 2;
  const horizontalCenter = colX - layoutConfig.columnGap / 2;

  return [
    [left, verticalCenter],
    [horizontalCenter, top],
    [right, verticalCenter],
    [horizontalCenter, bottom],
  ];
}

function createPlaceholderBlockLayout(width: StaticSize['width'], content: PlaceholderBlockContent): BlockLayoutSpec {
  let height = null;
  if (content.placeholderType === 'add-native-e-signature') {
    height = 160;
  }
  return {
    spec: {
      type: 'static',
      width,
      height,
    },
    verticalAlign: 'center',
  };
}

export function createTextBlockLayout(width: CalculatedHeightSize['width']): BlockLayoutSpec {
  return {
    spec: {
      type: 'calculated-height',
      width,
      height: null,
    },
    verticalAlign: 'center',
  };
}

export function createImageBlockLayout(
  width: AutoHeightSize['width'],
  {
    aspectRatio,
  }: {
    aspectRatio: Nullable<AspectRatio>;
  }
): BlockLayoutSpec {
  return {
    spec: {
      type: 'auto-height',
      width,
      height: null,
      aspectRatio,
    },
    verticalAlign: 'center',
  };
}

function createVideoBlockLayout(
  width: AutoHeightSize['width'],
  {
    aspectRatio,
  }: {
    aspectRatio: Nullable<AspectRatio>;
  }
): BlockLayoutSpec {
  return {
    spec: {
      type: 'auto-height',
      width,
      height: null,
      aspectRatio,
    },
    verticalAlign: 'center',
  };
}

function createPdfBlockLayout(
  width: AutoHeightSize['width'],
  {
    aspectRatio,
  }: {
    aspectRatio: Nullable<AspectRatio>;
  }
): BlockLayoutSpec {
  return {
    spec: {
      type: 'auto-height',
      width,
      height: null,
      aspectRatio,
    },
    verticalAlign: 'center',
  };
}

function createNativeESignatureBlockLayout(width: StaticSize['width']): BlockLayoutSpec {
  return {
    spec: {
      type: 'calculated-height',
      width,
      height: 160,
    },
    verticalAlign: 'top',
  };
}

function createBlockLayout(
  blockContent: BlockContent,
  width: BlockLayoutSpec['spec']['width'],
  params: BlockLayoutExtraParams,
  layoutConfig: LayoutConfig
): Block['layout'] {
  const { type } = blockContent;

  let blockLayout: Block['layout'];
  if (type === 'placeholder') {
    const content = blockContent as PlaceholderBlockContent;
    blockLayout = createPlaceholderBlockLayout(width, content);
  } else if (type === 'text') {
    blockLayout = createTextBlockLayout(width);
  } else if (type === 'image') {
    blockLayout = createImageBlockLayout(width, {
      aspectRatio: params.aspectRatio || null,
    });
  } else if (type === 'mutual-action-plan') {
    blockLayout = createMutualActionPlanBlockLayout(width);
  } else if (type === 'link') {
    if (blockContent.displayOption === 'inline') {
      blockLayout = createLinkBlockLayout(width);
    } else {
      blockLayout = createLinkBlockLayout(width);
    }
  } else if (type === 'pdf') {
    blockLayout = createPdfBlockLayout(width, {
      aspectRatio: params.aspectRatio || null,
    });
  } else if (type === 'table') {
    blockLayout = createTableBlockLayout(width);
  } else if (type === 'attachment') {
    blockLayout = createFileBlockLayout(width);
  } else if (type === 'video') {
    blockLayout = createVideoBlockLayout(width, {
      aspectRatio: params.aspectRatio || null,
    });
  } else if (type === 'logo') {
    blockLayout = createLogoBlockLayout(width);
  } else if (type === 'ms-office') {
    blockLayout = createFileBlockLayout(width);
  } else if (type === 'contents-list') {
    blockLayout = createContentsListLayout(width);
  } else if (type === 'talk-to-journey') {
    blockLayout = createLinkBlockLayout(width);
  } else if (type === 'signup') {
    blockLayout = createSignupBlockLayout(width);
  } else if (type === 'native-e-signature') {
    blockLayout = createNativeESignatureBlockLayout(width);
  } else if (type === 'cta-button') {
    blockLayout = createCtaButtonLayout(width);
  } else {
    const _exhaustiveCheck: never = type;
    throw new Error(`Unexpected block type: ${_exhaustiveCheck}`);
  }
  return blockLayout!;
}

type EditorLayoutInputs = LayoutInputs & {
  outerAreaLeftOffset: number;
  outerAreaRightOffset: number;
};

export class LayoutManager extends EventEmitter {
  sections: Section[] = [];
  sectionGrids: SectionGrid[] = [];
  blockContentSizes: BlockContentSizeMap = {};
  layoutConfig: LayoutConfig;
  boundaries: Boundary[] = [];
  inputs: Nullable<EditorLayoutInputs> = null;
  outputs: Nullable<LayoutOutputs> = null;
  constructor(layoutConfig: LayoutConfig = DEFAULT_LAYOUT_CONFIG) {
    super();
    this.layoutConfig = layoutConfig;
  }
  initialize(sections: Section[]) {
    this.sections = sections;
    this._updateSectionGrids();
    this._invalidate('initialize');
  }
  reset() {
    this.sections = [];
    this.sectionGrids = [];
    this.blockContentSizes = {};
    this.boundaries = [];
    this.outputs = null;
    this.inputs = null;
  }
  setLayoutInputs(inputs: EditorLayoutInputs) {
    // console.log('lm setLayoutInputs', inputs);
    this.inputs = inputs;
    this._updateSectionGrids();
    this._invalidate('setLayoutInputs');
  }
  setBlockContentSize(id: Block['id'], size: Nullable<Size>) {
    // console.log('lm setBlockContentSize', id, { ...size });
    this.blockContentSizes[id] = size;
    this._updateSectionGrids();
    this._invalidate(`setBlockContentSize`);
  }
  getPasteSectionOperations({
    section,
    pasteSectionIndex,
  }: {
    section: Section | Section[];
    pasteSectionIndex: number;
  }): Operation[] {
    const newSectionId = generateBlockId();
    const newSectionIndex = pasteSectionIndex + 1;
    const operations: Operation[] = [];

    if (!Array.isArray(section)) {
      operations.push({
        type: 'create-section',
        id: newSectionId,
        index: newSectionIndex,
        name: `(Copy) ${section.name}`,
      });

      if (section.blocks.length > 0) {
        for (const block of section.blocks) {
          const { width, ...spec } = block.layout.spec;
          operations.push({
            type: 'create-block',
            sectionId: newSectionId,
            block: {
              id: generateBlockId(),
              position: generateKeyBetween(block.position, null),
              content: block.content,
              layout: createBlockLayout(block.content, width, spec, this.layoutConfig),
            },
          });
        }
      }

      return operations;
    }

    section.reverse().map((s) => {
      const sectionId = generateBlockId();

      operations.push({
        type: 'create-section',
        id: sectionId,
        index: newSectionIndex,
        name: s.name as string,
      });

      if (s.blocks.length > 0) {
        for (const block of s.blocks) {
          const blockId = generateBlockId();
          const { width, ...spec } = block.layout.spec;
          operations.push({
            type: 'create-block',
            sectionId: sectionId,
            block: {
              id: blockId,
              position: generateKeyBetween(block.position, null),
              content: block.content,
              layout: createBlockLayout(block.content, width, spec, this.layoutConfig),
            },
          });
        }
      }

      const firstSection = operations.find(({ type }) => type === 'create-section') as CreateSectionOperation;

      operations.push({
        type: 'folder-update',
        id: sectionId,
        folder_key: `${firstSection.name?.replaceAll(' ', '-')}-${firstSection.id.substring(0, 8)}`,
      });
    });

    return operations;
  }
  getAppendSectionOperations({
    sectionId,
    newBlockParams,
  }: {
    sectionId: Section['id'];
    newBlockParams?: {
      blockId: Block['id'];
      blockContent: BlockContent;
      layoutExtraParams: BlockLayoutExtraParams;
    };
  }): Operation[] {
    const operations: Operation[] = [
      {
        type: 'create-section',
        id: sectionId,
        index: this.sections.length,
      },
    ];
    if (newBlockParams) {
      operations.push({
        type: 'create-block',
        sectionId,
        block: {
          id: newBlockParams.blockId,
          position: generateKeyBetween(null, null),
          content: newBlockParams.blockContent,
          layout: createBlockLayout(
            newBlockParams.blockContent,
            '100%',
            newBlockParams.layoutExtraParams,
            this.layoutConfig
          ),
        },
      });
    }
    return operations;
  }
  getInsertSectionAtBoundaryOperations({
    sectionId,
    boundary,
  }: {
    sectionId: Section['id'];
    boundary: Boundary;
  }): Operation[] {
    if (boundary.orientation === 'vertical') {
      return [];
    }
    const { sectionGrids } = this;
    const { sectionIndex, insertionRow } = boundary;
    const { blockGrid } = sectionGrids[sectionIndex];

    const operations: Operation[] = [];
    if (insertionRow === 0 || insertionRow === blockGrid.length) {
      operations.push({
        type: 'create-section',
        id: sectionId,
        index: insertionRow === 0 ? sectionIndex : sectionIndex + 1,
      });
      return operations;
    } else {
      operations.push({
        type: 'create-section',
        id: sectionId,
        index: sectionIndex + 1,
      });
      const blockGridToMove = blockGrid.slice(insertionRow);
      let newPosition = null;
      for (const blocks of blockGridToMove) {
        for (const block of blocks) {
          newPosition = generateKeyBetween(newPosition, null);
          operations.push({
            type: 'move-block',
            id: block.id,
            to: {
              sectionId,
              newPosition,
            },
          });
        }
      }

      return operations;
    }
  }
  getMoveSectionOperations({
    sectionId,
    newSectionIndex,
  }: {
    sectionId: Section['id'];
    newSectionIndex: number;
  }): Operation[] {
    return [
      {
        type: 'move-section',
        id: sectionId,
        to: {
          index: newSectionIndex,
        },
      },
    ];
  }
  getMergeSectionsOperations({
    previousSectionId,
    nextSectionId,
  }: {
    previousSectionId: Section['id'];
    nextSectionId: Section['id'];
  }): Operation[] {
    const { sections } = this;
    const previousSection = sections.find((s) => s.id === previousSectionId);
    const nextSection = sections.find((s) => s.id === nextSectionId);

    if (!nextSection || !previousSection) {
      return [];
    }
    const operations: Operation[] = [];
    const lastBlock = last(previousSection.blocks);
    let newPosition = lastBlock ? lastBlock.position : null;
    for (const block of nextSection.blocks) {
      newPosition = generateKeyBetween(newPosition, null);
      operations.push({
        type: 'move-block',
        id: block.id,
        to: {
          sectionId: previousSection.id,
          newPosition,
        },
      });
    }
    operations.push({
      type: 'delete-section',
      id: nextSection.id,
    });
    return operations;
  }
  getAppendBlockOperations({
    blockId,
    blockContent,
    layoutExtraParams,
    sectionId,
    addToNewRow = false,
  }: {
    blockId: Block['id'];
    blockContent: BlockContent;
    layoutExtraParams: BlockLayoutExtraParams;
    sectionId?: Section['id'];
    addToNewRow?: boolean;
  }): Operation[] {
    const { sections, sectionGrids, layoutConfig, outputs } = this;
    if (!outputs) {
      return [];
    }
    if (!sectionId) {
      sectionId = sections[sections.length - 1].id;
    }
    const sectionIndex = sections.findIndex((s) => s.id === sectionId);
    if (sectionIndex === -1) {
      return [];
    }

    const operations: Operation[] = [];
    let block: Block | undefined;
    const { blockGrid } = sectionGrids[sectionIndex];
    if (blockGrid.length > 0) {
      const lastRow = blockGrid[blockGrid.length - 1];
      if (lastRow.length > 0 && lastRow.length < NEW_BLOCK_COLUMN_THRESHOLD && !addToNewRow) {
        const lastRowBlock = last(lastRow)!;

        const otherBlocksNewWidthPercentage = 100 - 100 / (lastRow.length + 1);
        const resizeFactor = otherBlocksNewWidthPercentage / 100;

        let otherBlocksNewWidthSum = 0;
        lastRow.forEach((b) => {
          const newWidth = getWidthPercentage(b.layout.spec.width, outputs.gridWidth) * resizeFactor;
          operations.push({
            type: 'set-block-width',
            id: b.id,
            width: `${newWidth}%`,
          });
          otherBlocksNewWidthSum += newWidth;
        });
        operations.push({
          type: 'create-block',
          block: {
            id: blockId,
            position: generateKeyBetween(lastRowBlock.position, null),
            content: blockContent,
            layout: createBlockLayout(
              blockContent,
              `${100 - otherBlocksNewWidthSum}%`,
              layoutExtraParams,
              layoutConfig
            ),
          },
          sectionId,
        });
        return operations;
      }
    }
    operations.push({
      type: 'create-block',
      block: {
        id: blockId,
        position: genKeyForPos(sections[sectionIndex].blocks, sections[sectionIndex].blocks.length),
        content: blockContent,
        layout: createBlockLayout(blockContent, '100%', layoutExtraParams, layoutConfig),
      },
      sectionId,
    });
    return operations;
  }
  getReplaceBlockOperations({
    blockId,
    blockContent,
    layoutExtraParams,
  }: {
    blockId: Block['id'];
    blockContent: BlockContent;
    layoutExtraParams: BlockLayoutExtraParams;
  }): Operation[] {
    const { outputs, sectionGrids, layoutConfig } = this;
    if (!outputs) {
      return [];
    }
    const operations: Operation[] = [];
    for (let sectionGrid of sectionGrids) {
      const { blockGrid } = sectionGrid;
      for (let i = 0; i < blockGrid.length; i++) {
        const row = blockGrid[i];
        const blockIndex = row.findIndex((b) => b.id === blockId);
        if (blockIndex === -1) {
          continue;
        }
        const block = row[blockIndex];
        const otherBlocks = row.filter((b) => b.id !== blockId);
        const otherBlocksWidthPercentage = otherBlocks.reduce(
          (acc, b) => acc + getWidthPercentage(b.layout.spec.width, outputs.gridWidth),
          0
        );
        operations.push({
          type: 'set-block-content',
          id: block.id,
          content: blockContent,
        });
        operations.push({
          type: 'set-block-layout',
          id: block.id,
          layout: createBlockLayout(
            blockContent,
            `${100 - otherBlocksWidthPercentage}%`,
            layoutExtraParams,
            layoutConfig
          ),
        });
      }
    }
    return operations;
  }
  getSetSectionNameOperations({
    sectionId,
    name,
    setManually,
  }: {
    sectionId: Section['id'];
    name: Section['name'];
    setManually: boolean;
  }): Operation[] {
    return [
      {
        type: 'set-section-name',
        id: sectionId,
        name,
        setManually,
      },
    ];
  }
  getSetImageUrlAfterUploadOpersations({
    blockId,
    url,
  }: {
    blockId: Block['id'];
    url: ImageBlockContent['url'];
  }): Operation[] {
    for (let section of this.sections) {
      for (let block of section.blocks) {
        if (block.id === blockId) {
          if (block.content.type === 'image') {
            return [
              {
                type: 'update-block-content',
                id: blockId,
                content: produce(block.content, (c) => {
                  c.url = url;
                }),
              },
            ];
          }
        }
      }
    }
    return [];
  }
  getUpdateBlockContentOperations({
    blockId,
    updatedContent,
  }: {
    blockId: Block['id'];
    updatedContent: Partial<BlockContent>;
  }): Operation[] {
    if (findBlockById(this.sections, blockId) === null) {
      return [];
    }

    return [
      {
        type: 'update-block-content',
        id: blockId,
        content: updatedContent,
      },
    ];
  }
  getSetBlockContentUUIDOperations({
    blockId,
    contentUUID,
  }: {
    blockId: Block['id'];
    contentUUID: Nullable<Content['uuid']>;
  }): Operation[] {
    if (findBlockById(this.sections, blockId) === null) {
      return [];
    }

    return [
      {
        type: 'set-block-content-uuid',
        id: blockId,
        contentUUID,
      },
    ];
  }
  getInsertBlockAtBoundaryOperations({
    blockId,
    blockContent,
    layoutExtraParams,
    boundary,
  }: {
    blockId: Block['id'];
    blockContent: BlockContent;
    layoutExtraParams: BlockLayoutExtraParams;
    boundary: Boundary;
  }): Operation[] {
    const operations: Operation[] = [];
    const { sections, sectionGrids, outputs, layoutConfig } = this;
    if (!outputs) {
      return operations;
    }
    let { orientation, insertionRow, insertionCol, sectionIndex } = boundary;
    if (sectionIndex >= sections.length) {
      console.warn('sectionIndex too high');
      throw new Error('sectionIndex too high');
    }
    const sectionGrid = sectionGrids[sectionIndex];
    const { blockGrid } = sectionGrid;
    if (insertionRow > blockGrid.length) {
      console.warn('insertionRow too high');
      insertionRow = blockGrid.length;
    }

    if (insertionRow === blockGrid.length) {
      const index = sections[sectionIndex].blocks.length;
      operations.push({
        type: 'create-block',
        block: {
          id: blockId,
          position: genKeyForPos(sections[sectionIndex].blocks, index),
          content: blockContent,
          layout: createBlockLayout(blockContent, '100%', layoutExtraParams, layoutConfig),
        },
        sectionId: sections[sectionIndex].id,
      });
      return operations;
    }
    if (orientation === 'horizontal') {
      const index = blockGridIndexToBlockListIndex(blockGrid, insertionRow, 0);
      operations.push({
        type: 'create-block',
        block: {
          id: blockId,
          position: genKeyForPos(sections[sectionIndex].blocks, index),
          content: blockContent,
          layout: createBlockLayout(blockContent, '100%', layoutExtraParams, layoutConfig),
        },
        sectionId: sections[sectionIndex].id,
      });
      return operations;
    }
    const row = blockGrid[insertionRow];
    if (insertionCol > row.length) {
      console.warn('insertionCol too high');
      insertionCol = row.length;
    }

    if (row.length >= MAX_COLUMNS) {
      if (insertionCol === row.length) {
        const index = blockGridIndexToBlockListIndex(blockGrid, insertionRow + 1, 0);
        operations.push({
          type: 'create-block',
          block: {
            id: blockId,
            position: genKeyForPos(sections[sectionIndex].blocks, index),
            content: blockContent,
            layout: createBlockLayout(blockContent, '100%', layoutExtraParams, layoutConfig),
          },
          sectionId: sections[sectionIndex].id,
        });
        return operations;
      } else {
        const lastBlock = last(row)!;
        const index = blockGridIndexToBlockListIndex(blockGrid, insertionRow, insertionCol);
        operations.push({
          type: 'create-block',
          block: {
            id: blockId,
            position: genKeyForPos(sections[sectionIndex].blocks, index),
            content: blockContent,
            layout: createBlockLayout(blockContent, lastBlock.layout.spec.width, layoutExtraParams, layoutConfig),
          },
          sectionId: sections[sectionIndex].id,
        });
        operations.push({
          type: 'set-block-width',
          id: lastBlock.id,
          width: '100%',
        });
        return operations;
      }
    } else {
      const otherBlocks = row.filter((b) => b.id !== blockId);
      const resizeFactor = 1 / (otherBlocks.length + 1);
      for (let i = 0; i < otherBlocks.length; i++) {
        const block = otherBlocks[i];
        operations.push({
          type: 'set-block-width',
          id: block.id,
          width: `${resizeFactor * 100}%`,
        });
      }
      const index = blockGridIndexToBlockListIndex(blockGrid, insertionRow, insertionCol);
      operations.push({
        type: 'create-block',
        block: {
          id: blockId,
          position: genKeyForPos(sections[sectionIndex].blocks, index),
          content: blockContent,
          layout: createBlockLayout(blockContent, `${resizeFactor * 100}%`, layoutExtraParams, layoutConfig),
        },
        sectionId: sections[sectionIndex].id,
      });
      return operations;
    }

    // if (row.length === 0) {
    //   const index = blockGridIndexToBlockListIndex(blockGrid, insertionRow, 0);
    //   operations.push({
    //     type: 'create-block',
    //     block: {
    //       id: blockId,
    //       position: genKeyForPos(sections[sectionIndex].blocks, index),
    //       content: blockContent,
    //       layout: createBlockLayout(blockContent, '100%', layoutExtraParams, layoutConfig),
    //     },
    //     sectionId: sections[sectionIndex].id,
    //   });
    //   return operations;
    // }
    // if (row.length === 1) {
    //   const [existingBlock] = row;
    //   operations.push({
    //     type: 'set-block-width',
    //     id: existingBlock.id,
    //     width: '50%',
    //   });
    //   const index = blockGridIndexToBlockListIndex(blockGrid, insertionRow, insertionCol);
    //   operations.push({
    //     type: 'create-block',
    //     block: {
    //       id: blockId,
    //       position: genKeyForPos(sections[sectionIndex].blocks, index),
    //       content: blockContent,
    //       layout: createBlockLayout(blockContent, '50%', layoutExtraParams, layoutConfig),
    //     },
    //     sectionId: sections[sectionIndex].id,
    //   });
    //   return operations;
    // }
    // if (row.length === 2) {
    //   if (insertionCol === 2) {
    //     const index = blockGridIndexToBlockListIndex(blockGrid, insertionRow + 1, 0);
    //     operations.push({
    //       type: 'create-block',
    //       block: {
    //         id: blockId,
    //         position: genKeyForPos(sections[sectionIndex].blocks, index),
    //         content: blockContent,
    //         layout: createBlockLayout(blockContent, '100%', layoutExtraParams, layoutConfig),
    //       },
    //       sectionId: sections[sectionIndex].id,
    //     });
    //     return operations;
    //   }
    //   if (insertionCol === 0 || insertionCol === 1) {
    //     const [firstBlock, lastBlock] = row;
    //     const firstBlockWidthPercentage = getWidthPercentage(firstBlock.layout.spec.width, outputs.gridWidth);
    //     const index = blockGridIndexToBlockListIndex(blockGrid, insertionRow, insertionCol);
    //     operations.push({
    //       type: 'create-block',
    //       block: {
    //         id: blockId,
    //         position: genKeyForPos(sections[sectionIndex].blocks, index),
    //         content: blockContent,
    //         layout: createBlockLayout(
    //           blockContent,
    //           `${100 - firstBlockWidthPercentage}%`,
    //           layoutExtraParams,
    //           layoutConfig
    //         ),
    //       },
    //       sectionId: sections[sectionIndex].id,
    //     });
    //     operations.push({
    //       type: 'set-block-width',
    //       id: lastBlock.id,
    //       width: '100%',
    //     });
    //     return operations;
    //   }
    // }
    // return operations;
  }
  getMoveBlockToBoundaryOperations(id: Block['id'], boundaryParams: BoundaryParams): Operation[] {
    // console.log('lm getMoveBlockToBoundaryOperations');
    const operations: Operation[] = [];
    const { outputs, sectionGrids, sections } = this;
    if (!outputs) {
      return operations;
    }
    const block = findBlock(sections, id);
    if (!block) {
      return operations;
    }
    const blockLocation = locateBlockInGrid(sectionGrids, id);
    if (!blockLocation) {
      return operations;
    }

    let {
      orientation,
      sectionIndex: newSectionIndex,
      insertionRow: newRowIndex,
      insertionCol: newColIndex,
    } = boundaryParams;
    const { rowIndex: initialRowIndex, colIndex: initialColIndex, sectionIndex: initialSectionIndex } = blockLocation;

    const currentListIndex = sections[initialSectionIndex].blocks.findIndex((b) => b.id === id);

    let newListIndex = blockGridIndexToBlockListIndex(
      sectionGrids[newSectionIndex].blockGrid,
      newRowIndex,
      newColIndex
    );

    const initialRow = sectionGrids[initialSectionIndex].blockGrid[initialRowIndex];
    const newRow = sectionGrids[newSectionIndex].blockGrid[newRowIndex];

    if (initialSectionIndex === newSectionIndex && currentListIndex === newListIndex) {
      // We need to check if it's a no-op
      if (orientation === 'horizontal') {
        if (initialRow.length === 1) {
          return operations;
        }
      } else if (orientation === 'vertical') {
        if (newRow.length === MAX_COLUMNS) {
          return operations;
        }
      }
    }

    // First, update the sizes of the blocks in the initial row
    if (initialRow.length > 1) {
      if (initialSectionIndex !== newSectionIndex || orientation === 'horizontal' || initialRowIndex !== newRowIndex) {
        const deletionBlock = initialRow[initialColIndex];
        const otherBlocks = initialRow.filter((b) => b.id !== deletionBlock.id);
        const deletionBlockWidthPercentage = getWidthPercentage(deletionBlock.layout.spec.width, outputs.gridWidth);
        const resizeFactor = 100 / (100 - deletionBlockWidthPercentage);
        const lastBlock = otherBlocks[otherBlocks.length - 1];
        let otherBlocksNewWidthPercentageSum = 0;
        otherBlocks.forEach((b) => {
          if (b.id === lastBlock.id) {
            return;
          }
          const widthPercentage = getWidthPercentage(b.layout.spec.width, outputs.gridWidth);
          const newWidthPercentage = widthPercentage * resizeFactor;
          operations.push({
            type: 'set-block-width',
            id: b.id,
            width: `${newWidthPercentage}%`,
          });
          otherBlocksNewWidthPercentageSum += newWidthPercentage;
        });
        operations.push({
          type: 'set-block-width',
          id: lastBlock.id,
          width: `${100 - otherBlocksNewWidthPercentageSum}%`,
        });

        // const otherBlock = initialRow[initialColIndex === 0 ? 1 : 0];
        // operations.push({
        //   type: 'set-block-width',
        //   id: otherBlock.id,
        //   width: '100%',
        // });
      }
    }

    // Then, update the sizes of the blocks in the new row
    if (orientation === 'horizontal') {
      operations.push({
        type: 'set-block-width',
        id: block.id,
        width: '100%',
      });
    } else if (orientation === 'vertical') {
      if (initialSectionIndex !== newSectionIndex || initialRowIndex !== newRowIndex) {
        if (newRow.length >= MAX_COLUMNS) {
          if (newColIndex >= MAX_COLUMNS) {
            operations.push({
              type: 'set-block-width',
              id: block.id,
              width: '100%',
            });
          } else {
            const lastBlock = newRow[newRow.length - 1];
            const lastBlockWidthPercentage = getWidthPercentage(lastBlock.layout.spec.width, outputs.gridWidth);
            operations.push({
              type: 'set-block-width',
              id: lastBlock.id,
              width: '100%',
            });
            operations.push({
              type: 'set-block-width',
              id: block.id,
              width: `${lastBlockWidthPercentage}%`,
            });
          }
        } else {
          const resizeFactor = newRow.length / (newRow.length + 1);
          let otherBlocksNewWidthPercentageSum = 0;
          newRow.forEach((b) => {
            const widthPercentage = getWidthPercentage(b.layout.spec.width, outputs.gridWidth);
            const newWidthPercentage = widthPercentage * resizeFactor;
            operations.push({
              type: 'set-block-width',
              id: b.id,
              width: `${newWidthPercentage}%`,
            });
            otherBlocksNewWidthPercentageSum += newWidthPercentage;
          });
          operations.push({
            type: 'set-block-width',
            id: block.id,
            width: `${100 - otherBlocksNewWidthPercentageSum}%`,
          });
        }

        //   if (newRow.length === 1) {
        //     const otherBlock = newRow[0];
        //     operations.push({
        //       type: 'set-block-width',
        //       id: otherBlock.id,
        //       width: '50%',
        //     });
        //     operations.push({
        //       type: 'set-block-width',
        //       id: block.id,
        //       width: '50%',
        //     });
        //   } else if (newRow.length === 2) {
        //     if (newColIndex === 2) {
        //       operations.push({
        //         type: 'set-block-width',
        //         id: block.id,
        //         width: '100%',
        //       });
        //     } else if (newColIndex === 1) {
        //       const lastBlock = newRow[1];
        //       operations.push({
        //         type: 'set-block-width',
        //         id: lastBlock.id,
        //         width: '100%',
        //       });
        //       operations.push({
        //         type: 'set-block-width',
        //         id: block.id,
        //         width: '50%',
        //       });
        //     } else if (newColIndex === 0) {
        //       const [firstBlock, lastBlock] = newRow;
        //       const firstBlockWidthPercentage = getWidthPercentage(firstBlock.layout.spec.width, outputs.gridWidth);
        //       operations.push({
        //         type: 'set-block-width',
        //         id: lastBlock.id,
        //         width: '100%',
        //       });
        //       operations.push({
        //         type: 'set-block-width',
        //         id: block.id,
        //         width: `${100 - firstBlockWidthPercentage}%`,
        //       });
        //     }
        //   }
      }
    }
    operations.push({
      type: 'move-block',
      id: block.id,
      to: {
        sectionId: sections[newSectionIndex].id,
        newPosition: genKeyForPos(sections[newSectionIndex].blocks, newListIndex),
      },
    });
    return operations;
  }
  getSetBlockVerticalAlignmentOperations(id: Block['id'], verticalAlignment: BlockVerticalAlignment): Operation[] {
    const { sections } = this;

    const block = findBlock(sections, id);
    if (!block) {
      throw new Error('Block not found');
    }
    const operations: Operation[] = [];
    operations.push({
      type: 'set-block-layout',
      id: id,
      layout: {
        ...block.layout,
        verticalAlign: verticalAlignment,
      },
    });
    return operations;
  }
  getResizeHorizontalOperations(boundary: Boundary, boundaryX: number): Operation[] {
    const { outputs, layoutConfig, sections, sectionGrids } = this;
    if (!outputs) {
      return [];
    }
    const { sectionIndex, insertionRow: boundaryRowIndex, insertionCol: boundaryColIndex } = boundary;
    const operations: Operation[] = [];
    const section = sections[sectionIndex];
    const { blockGrid } = sectionGrids[sectionIndex];
    if (boundaryRowIndex >= blockGrid.length) {
      console.warn('insertionRow too high');
      return [];
    }
    const row = blockGrid[boundaryRowIndex];
    if (row.length < 2) {
      return [];
    }
    if (boundaryColIndex >= row.length) {
      console.warn('insertionCol too high');
      return [];
    } else if (boundaryColIndex < 1) {
      console.warn('insertionCol too low');
      return [];
    }
    const availableWidth = outputs.gridWidth - layoutConfig.columnGap * (row.length - 1);
    const leftBlock = row[boundaryColIndex - 1];
    const rightBlock = row[boundaryColIndex];

    const leftBlockWidth = calculateWidth(leftBlock.layout.spec.width, availableWidth);
    const rightBlockWidth = calculateWidth(rightBlock.layout.spec.width, availableWidth);

    const blocksBeforeLeftBlock = row.slice(0, boundaryColIndex - 1);
    const leftBlockLeftEdge =
      blocksBeforeLeftBlock.reduce((sum, b) => sum + calculateWidth(b.layout.spec.width, availableWidth), 0) +
      layoutConfig.columnGap * (boundaryColIndex - 1);

    const rightBlockRightEdge = leftBlockLeftEdge + leftBlockWidth + layoutConfig.columnGap + rightBlockWidth;
    const clampedBoundaryX = clamp(
      boundaryX,
      leftBlockLeftEdge + BLOCK_MIN_WIDTH + layoutConfig.columnGap / 2,
      rightBlockRightEdge - BLOCK_MIN_WIDTH - layoutConfig.columnGap / 2
    );

    const leftBlockNewWidth = clampedBoundaryX - leftBlockLeftEdge - layoutConfig.columnGap / 2;

    const leftBlockNewWidthPercentage = (leftBlockNewWidth / availableWidth) * 100;
    const allOtherBlocksWidthPercentage = row
      .filter((b) => b.id !== leftBlock.id && b.id !== rightBlock.id)
      .reduce((sum, b) => sum + getWidthPercentage(b.layout.spec.width, availableWidth), 0);
    const rightBlockNewWidthPercentage = 100 - leftBlockNewWidthPercentage - allOtherBlocksWidthPercentage;

    // const leftBlockWidth = clamp(
    //   boundaryX - layoutConfig.columnGap / 2 - leftBlockLeftEdge,
    //   BLOCK_MIN_WIDTH,
    //   outputs.innerActualWidth - layoutConfig.columnGap - BLOCK_MIN_WIDTH
    // );

    // const leftBlockWidth = clamp(
    //   boundaryX - layoutConfig.columnGap / 2,
    //   BLOCK_MIN_WIDTH,
    //   outputs.innerActualWidth - layoutConfig.columnGap - BLOCK_MIN_WIDTH
    // );
    // const [firstBlock, secondBlock] = row;
    // const maxTotalRowWidth = 98;

    // let leftWidthPercentage = clamp(
    //   (leftBlockWidth / (outputs.innerActualWidth - layoutConfig.columnGap)) * 100,
    //   2,
    //   maxTotalRowWidth
    // );

    // const minFractionFirstBlock = BLOCKS_MIN_WIDTH_FRAC[firstBlock.content.type];
    // if (minFractionFirstBlock) {
    //   leftWidthPercentage = Math.max(maxTotalRowWidth * minFractionFirstBlock, leftWidthPercentage);
    // }

    // let rightWidthPercentage = 100 - leftWidthPercentage;
    // const minFractionSecondBlock = BLOCKS_MIN_WIDTH_FRAC[secondBlock.content.type];
    // if (minFractionSecondBlock) {
    //   rightWidthPercentage = Math.max(maxTotalRowWidth * minFractionSecondBlock, rightWidthPercentage);
    //   leftWidthPercentage = 100 - rightWidthPercentage;
    // }

    operations.push({
      type: 'set-block-width',
      id: leftBlock.id,
      width: `${leftBlockNewWidthPercentage}%`,
    });
    operations.push({
      type: 'set-block-width',
      id: rightBlock.id,
      width: `${rightBlockNewWidthPercentage}%`,
    });
    return operations;
  }
  getResizeVerticalOperations(boundary: Boundary, bottomBoundaryY: number): Operation[] {
    const { outputs, blockContentSizes, layoutConfig } = this;
    if (!outputs) {
      return [];
    }
    const blockRects = outputs.renderElements.reduce((blockRects, renderElement) => {
      if (renderElement.type === 'block') {
        blockRects[renderElement.block.id] = renderElement.rect;
      }
      return blockRects;
    }, {} as Record<string, Rect>);
    const { sectionIndex, insertionRow } = boundary;
    const rowIndex = insertionRow - 1;

    const operations: Operation[] = [];
    const { blockGrid } = this.sectionGrids[sectionIndex];
    if (rowIndex >= blockGrid.length) {
      console.warn('row too high');
      return [];
    }
    const row = blockGrid[rowIndex];
    if (rowIndex < 0) {
      console.warn('row too low');
      return [];
    }
    if (row.length === 0) {
      return [];
    }
    const rowRect = getSuperRect(row.map((block) => blockRects[block.id]!));
    const newRowHeight = bottomBoundaryY - rowRect.y - layoutConfig.rowGap / 2;
    // console.log('lm resizeVertical', newRowHeight);
    const blockMinHeights = row.map((block) => getMinBlockHeight(block, blockContentSizes[block.id] || null));
    const newHeight = max(newRowHeight, ROW_MIN_HEIGHT, ...blockMinHeights);
    row.forEach((block) => {
      operations.push({
        type: 'set-block-layout',
        id: block.id,
        layout: {
          ...block.layout,
          spec: {
            ...block.layout.spec,
            height: newHeight,
          },
        },
      });
    });
    return operations;
  }
  getDeleteBlockOperations(id: Block['id']): Operation[] {
    const { outputs } = this;
    if (!outputs) {
      return [];
    }
    const result = locateBlockInGrid(this.sectionGrids, id);
    if (!result) {
      return [];
    }
    const { sectionIndex, rowIndex, colIndex } = result;
    const sectionGrid = this.sectionGrids[sectionIndex];
    const operations: Operation[] = [];
    const { blockGrid } = sectionGrid;
    const row = blockGrid[rowIndex];
    const deletionBlock = row[colIndex];
    const otherBlocks = row.filter((block) => block.id !== id);
    if (otherBlocks.length > 0) {
      const deletionBlockWidthPercentage = getWidthPercentage(deletionBlock.layout.spec.width, outputs.gridWidth);

      const resizeFactor = 100 / Math.max(100 - deletionBlockWidthPercentage, 1);
      const lastBlock = otherBlocks[otherBlocks.length - 1];
      let otherBlocksNewWidthPercentageSum = 0;
      otherBlocks.forEach((block) => {
        if (block.id === lastBlock.id) {
          return;
        }
        const widthPercentage = getWidthPercentage(block.layout.spec.width, outputs.gridWidth);
        const newWidthPercentage = widthPercentage * resizeFactor;
        operations.push({
          type: 'set-block-width',
          id: block.id,
          width: `${newWidthPercentage}%`,
        });
        otherBlocksNewWidthPercentageSum += newWidthPercentage;
      });
      operations.push({
        type: 'set-block-width',
        id: lastBlock.id,
        width: `${100 - otherBlocksNewWidthPercentageSum}%`,
      });
    }
    // if (row.length === 2) {
    //   const otherBlock = row[0].id === id ? row[1] : row[0];
    //   operations.push({
    //     type: 'set-block-width',
    //     id: otherBlock.id,
    //     width: '100%',
    //   });
    // }
    operations.push({
      type: 'delete-block',
      blockId: id,
      sectionId: this.sections[sectionIndex].id,
    });
    return operations;
  }
  getDeleteSectionOperations(id: Section['id']): Operation[] {
    const index = this.sections.findIndex((section) => section.id === id);
    if (index === -1) {
      return [];
    }
    const operations: Operation[] = [];
    const section = this.sections[index];
    section.blocks.forEach((block) => {
      operations.push({
        type: 'delete-block',
        blockId: block.id,
        sectionId: id,
      });
    });
    operations.push({
      type: 'delete-section',
      id,
    });
    return operations;
  }
  getHiddenSectionOperations(id: Section['id']): Operation[] {
    const index = this.sections.findIndex((section) => section.id === id);
    if (index === -1) {
      return [];
    }
    const operations: Operation[] = [];
    const section = this.sections[index];

    operations.push({
      type: 'hidden-section',
      id,
      hidden: !section.hidden,
    });

    return operations;
  }
  getFolderUpdateOperations(id: Section['id'], folder_key: Section['folderID']): Operation[] {
    const index = this.sections.findIndex((section) => section.id === id);
    const isFolderHidden = this.sections.find(({ folderID }) => folderID === folder_key)?.hidden || false;

    if (index === -1) {
      return [];
    }
    const operations: Operation[] = [];

    operations.push({
      type: 'folder-update',
      id,
      folder_key: folder_key ? folder_key : '',
    });

    operations.push({
      type: 'hidden-section',
      id,
      hidden: isFolderHidden,
    });

    return operations;
  }
  getFoldersUpdateOperations(sections: Array<{ id: string; folder_key?: string }>): Operation[] {
    const operations: Operation[] = [];
    const validSections = sections.filter(({ id }) => this.sections.find((section) => section.id === id));
    operations.push({
      type: 'folders-update',
      sections: validSections,
    });

    return operations;
  }
  getFolderSetNameOperations(id: Section['id'], folder_name: Section['folderName']): Operation[] {
    const index = this.sections.findIndex((section) => section.id === id);
    if (index === -1) {
      return [];
    }
    const operations: Operation[] = [];

    operations.push({
      type: 'folder-set-name',
      id,
      folder_name: folder_name ? folder_name : '',
    });

    return operations;
  }
  applyOperations(operations: Operation[]) {
    let newSections = this.sections;
    for (let operation of operations) {
      if (operation.type === 'move-block') {
        const {
          to: { sectionId, newPosition },
          id: blockId,
        } = operation;
        newSections = produce(newSections, (sections) => {
          const fromSectionIndex = findSectionIndexByBlockId(sections, blockId)!;
          let fromBlockIndex = sections[fromSectionIndex].blocks.findIndex((b) => b.id === blockId);
          const toSectionIndex = findSectionIndexById(sections, sectionId)!;
          const blockToMove = sections[fromSectionIndex].blocks.find((b) => b.id === blockId)!;

          blockToMove.position = newPosition;
          sections[fromSectionIndex].blocks.splice(fromBlockIndex, 1);
          sections[toSectionIndex].blocks.push(blockToMove);
          sections[toSectionIndex].blocks = sortBy(sections[toSectionIndex].blocks, 'position');
        });
      } else if (operation.type === 'create-block') {
        const op: CreateBlockOperation = operation;
        newSections = produce(newSections, (sections) => {
          const sectionIndex = findSectionIndexById(sections, op.sectionId)!;
          const section = sections[sectionIndex];
          section.blocks.push(op.block);
          sections[sectionIndex].blocks = sortBy(sections[sectionIndex].blocks, 'position');
        });
      } else if (operation.type === 'delete-block') {
        const op: DeleteBlockOperation = operation;
        newSections = produce(newSections, (sections) => {
          const sectionIndex = sections.findIndex((s) => s.id === op.sectionId);
          const blockIndex = sections[sectionIndex].blocks.findIndex((b) => b.id === op.blockId);
          sections[sectionIndex].blocks.splice(blockIndex, 1);
        });
      } else if (operation.type === 'create-section') {
        const op: CreateSectionOperation = operation;
        newSections = produce(newSections, (sections) => {
          if (op.index < 0 || op.index > sections.length) {
            throw new Error(`Invalid section index: ${op.index}`);
          }
          sections.splice(op.index, 0, {
            id: op.id,
            name: op.name || null,
            nameSetManually: !!op.name,
            tainted: false,
            friendlyPath: getFriendlySectionPath(op.id, op.name || ''),
            blocks: [],
            hidden: false,
          });
        });
      } else if (operation.type === 'delete-section') {
        const op: DeleteSectionOperation = operation;
        newSections = produce(newSections, (sections) => {
          const sectionIndex = sections.findIndex((s) => s.id === op.id);
          if (sectionIndex === -1) {
            throw new Error(`Section not found: ${op.id}`);
          }
          sections.splice(sectionIndex, 1);
        });
      } else if (operation.type === 'hidden-section') {
        const op: HiddenSectionOperation = operation;
        newSections = produce(newSections, (sections) => {
          const section = sections.find((s) => s.id === op.id);
          if (!section) {
            throw new Error(`Section not found: ${op.id}`);
          }
          if (op.hidden) {
            section.hidden = true;
          }
          section.hidden = op.hidden;
        });
      } else if (operation.type === 'folder-update') {
        const op: FolderUpdateOperation = operation;
        newSections = produce(newSections, (sections) => {
          const section = sections.find((s) => s.id === op.id);
          if (!section) {
            throw new Error(`Section not found: ${op.id}`);
          }
          if (op.folder_key) {
            section.folderID = op.folder_key;
          }
          section.folderID = op.folder_key;
        });
      } else if (operation.type === 'folders-update') {
        const op: FoldersUpdateOperation = operation;
        newSections = produce(newSections, (sections) => {
          op.sections.forEach(({ id, folder_key }) => {
            const section = sections.find((s) => s.id === id);
            if (!section) {
              throw new Error(`Section not found: ${id}`);
            }
            if (folder_key) {
              section.folderID = folder_key;
            }
            section.folderID = folder_key;
          });
        });
      } else if (operation.type === 'folder-set-name') {
        const op: FolderSetNameOperation = operation;
        newSections = produce(newSections, (sections) => {
          const section = sections.find((s) => s.id === op.id);
          if (!section) {
            throw new Error(`Section not found: ${op.id}`);
          }
          if (op.folder_name) {
            section.folderName = op.folder_name;
          }
          section.folderName = op.folder_name;
        });
      } else if (operation.type === 'move-section') {
        const op: MoveSectionOperation = operation;
        newSections = produce(newSections, (sections) => {
          const sectionIndex = sections.findIndex((s) => s.id === op.id);
          if (sectionIndex === -1) {
            throw new Error(`Section not found: ${op.id}`);
          }
          if (op.to.index < 0 || op.to.index > sections.length) {
            throw new Error(`Invalid section index: ${op.to.index}`);
          }
          const section = sections[sectionIndex];
          sections.splice(sectionIndex, 1);
          sections.splice(op.to.index, 0, section);
        });
      } else if (operation.type === 'set-section-name') {
        const op: SetSectionNameOperation = operation;
        newSections = produce(newSections, (sections) => {
          const section = sections.find((s) => s.id === op.id);
          if (!section) {
            throw new Error(`Section not found: ${op.id}`);
          }
          if (op.setManually) {
            section.nameSetManually = true;
          }
          section.name = op.name;
          section.friendlyPath = getFriendlySectionPath(op.id, op.name || '');
        });
      } else if (operation.type === 'set-block-width') {
        const op: SetBlockWidthOperation = operation;
        newSections = produce(newSections, (sections) => {
          const block = findBlockById(sections, op.id);
          if (!block) {
            throw new Error(`Block not found: ${op.id}`);
          }
          block.layout.spec.width = op.width;
        });
      } else if (operation.type === 'set-block-content') {
        const op: SetBlockContentOperation = operation;
        newSections = produce(newSections, (sections) => {
          const block = findBlockById(sections, op.id);
          if (!block) {
            throw new Error(`Block not found: ${op.id}`);
          }
          block.content = op.content;
        });
      } else if (operation.type === 'update-block-content') {
        const op: UpdateBlockContentOperation = operation;
        newSections = produce(newSections, (sections) => {
          const block = findBlockById(sections, op.id);
          if (!block) {
            throw new Error(`Block not found: ${op.id}`);
          }
          block.content = Object.assign({}, block.content, op.content);
        });
      } else if (operation.type === 'set-block-layout') {
        const op: SetBlockLayoutOperation = operation;
        newSections = produce(newSections, (sections) => {
          const block = findBlockById(sections, op.id);
          if (!block) {
            throw new Error(`Block not found: ${op.id}`);
          }
          block.layout = op.layout;
        });
      } else if (operation.type === 'set-block-content-uuid') {
        const op: SetBlockContentUUIDOperation = operation;
        newSections = produce(newSections, (sections) => {
          const block = findBlockById(sections, op.id);
          if (!block) {
            throw new Error(`Block not found: ${op.id}`);
          }
          (block.content as any).contentUUID = op.contentUUID;
        });
      } else {
        const assertNever: never = operation;
        throw new Error(`Unknown operation type: ${assertNever}`);
      }
    }
    this.sections = newSections;
    this._updateSectionGrids();
    this._invalidate('applyOperations');
  }
  _updateSectionGrids() {
    const { inputs } = this;
    if (!inputs) {
      return;
    }
    this.sections = produce(this.sections, (sections) => {
      sections.forEach((section) => {
        section.tainted = section.tainted || section.blocks.length > 0;
      });
    });
    this.sectionGrids = this.sections.map((section) => ({
      id: section.id,
      name: section.name,
      nameSetManually: section.nameSetManually,
      tainted: section.tainted,
      friendlyPath: section.friendlyPath,
      blockGrid: blocksToBlockGrid(section.blocks, inputs.innerAreaWidth, 'web'),
      hidden: section.hidden,
    }));
  }
  _updateSections() {
    this.sectionGrids = produce(this.sectionGrids, (sectionGrids) => {
      sectionGrids.forEach((sectionGrid) => {
        const blocks = blockGridToBlockList(sectionGrid.blockGrid);
        sectionGrid.tainted = sectionGrid.tainted || blocks.length > 0;
      });
    });
    this.sections = this.sectionGrids.map((sectionGrid) => {
      return {
        id: sectionGrid.id,
        name: sectionGrid.name,
        nameSetManually: sectionGrid.nameSetManually,
        tainted: sectionGrid.tainted,
        friendlyPath: sectionGrid.friendlyPath,
        blocks: blockGridToBlockList(sectionGrid.blockGrid),
        hidden: sectionGrid.hidden,
      };
    });
  }
  _invalidate(invalidator: string) {
    const { sections, sectionGrids, blockContentSizes, inputs } = this;
    if (!inputs) {
      return;
    }
    this.outputs = layout(
      {
        sections,
        sectionGrids,
        blockContentSizes,
        inputs,
        layoutMode: 'web',
        layoutConfig: this.layoutConfig,
      },
      {
        withEmptySections: true,
        withLastDivider: true,
      }
    );
    this._updateBoundaries();
    this.emit('update', invalidator);
  }
  _updateBoundaries() {
    const { inputs, outputs } = this;
    if (!inputs || !outputs) {
      return;
    }
    // console.log('lm _updateBoundaries');
    const {
      rowGap: GRID_ROW_GAP,
      columnGap: GRID_COLUMN_GAP,
      sectionGap: { min: SECTION_GAP_MIN, max: SECTION_GAP_MAX },
    } = this.layoutConfig;
    const { gridWidth, gridHeight, topOffset, sectionLayoutInfos } = outputs;

    const SECTION_GAP = Math.max(SECTION_GAP_MIN, Math.min(SECTION_GAP_MAX, inputs.innerAreaHeight / 4));

    let boundaries: Boundary[] = [];
    let rowStartX: number = 0;
    let colStartX = 0;
    let previousRowHeight = 0;
    let y = 0;
    for (let k = 0; k < this.sectionGrids.length; k++) {
      const sectionGrid = this.sectionGrids[k];
      const blockGrid = sectionGrid.blockGrid;
      const blockRects = outputs.renderElements.reduce((blockRects, renderElement) => {
        if (renderElement.type === 'block') {
          blockRects[renderElement.block.id] = renderElement.rect;
        }
        return blockRects;
      }, {} as Record<string, Rect>);
      const isEmptyBlockGrid = blockGrid.length === 0;
      for (let i = 0; i < blockGrid.length; i++) {
        const row = blockGrid[i];
        const maxRowHeight = max(...row.map((block) => blockRects[block.id]!.height)) || 0;
        const rowRect = getSuperRect(row.map((block) => blockRects[block.id]!));
        // boundary above each row
        boundaries.push({
          sectionIndex: k,
          orientation: 'horizontal',
          boundaryKey: `h.${k}.${i}.0`,
          x: rowRect.x,
          y: rowRect.y - (i == 0 ? BOUNDARY_VERTICAL_OFFSET : GRID_ROW_GAP / 2) - BOUNDARY_THICKNESS / 2,
          width: gridWidth,
          height: BOUNDARY_THICKNESS,
          insertionRow: i,
          insertionCol: 0,
          isFirstInRow: false,
          isLastInRow: false,
          isFirstInSection: i === 0,
          isLastInSection: i === blockGrid.length - 1,
          dragPolygon: getDragPolygonHorizontal({
            isFirst: i === 0,
            isLast: false,
            isFirstSection: k === 0,
            isLastSection: k === this.sectionGrids.length - 1,
            rowY: rowRect.y,
            previousRowHeight,
            rowHeight: maxRowHeight,
            inputs,
            outputs,
            layoutConfig: this.layoutConfig,
          }),
        });

        let previousColWidth = 0;
        for (let j = 0; j < row.length; j++) {
          const gridItem = row[j];
          const blockRect = blockRects[gridItem.id];
          const blockWidth = blockRect.width;
          // boundary before each column in a row
          boundaries.push({
            sectionIndex: k,
            boundaryKey: `v.${k}.${i}.${j}`,
            orientation: 'vertical',
            x: colStartX - (j == 0 ? BOUNDARY_HORIZONTAL_OFFSET : GRID_COLUMN_GAP / 2) - BOUNDARY_THICKNESS / 2,
            y: rowRect.y,
            width: BOUNDARY_THICKNESS,
            height: maxRowHeight,
            insertionRow: i,
            insertionCol: j,
            isFirstInRow: j === 0,
            isLastInRow: false,
            isFirstInSection: false,
            isLastInSection: false,
            dragPolygon: getDragPolygonVertical({
              isFirst: j === 0,
              isLast: false,
              isFirstRow: i === 0,
              isLastRow: i === blockGrid.length - 1,
              isFirstSection: k === 0,
              isLastSection: k === this.sectionGrids.length - 1,
              rowY: rowRect.y,
              rowHeight: maxRowHeight,
              colX: colStartX,
              colWidth: blockWidth,
              previousColWidth,
              inputs,
              outputs,
              layoutConfig: this.layoutConfig,
            }),
          });
          previousColWidth = blockWidth;
          colStartX += blockWidth + GRID_COLUMN_GAP;
        }
        // boundary at the end of each row
        boundaries.push({
          sectionIndex: k,
          boundaryKey: `v.${k}.${i}.${row.length}`,
          orientation: 'vertical',
          x: colStartX - GRID_COLUMN_GAP + BOUNDARY_VERTICAL_OFFSET - BOUNDARY_THICKNESS / 2,
          y: rowRect.y,
          width: BOUNDARY_THICKNESS,
          height: maxRowHeight,
          insertionRow: i,
          insertionCol: row.length,
          isFirstInRow: row.length === 0,
          isLastInRow: true,
          isFirstInSection: false,
          isLastInSection: false,
          dragPolygon: getDragPolygonVertical({
            isFirst: row.length === 0,
            isLast: true,
            isFirstRow: i === 0,
            isLastRow: i === blockGrid.length - 1,
            isFirstSection: k === 0,
            isLastSection: k === this.sectionGrids.length - 1,
            rowY: rowRect.y,
            rowHeight: maxRowHeight,
            colX: colStartX,
            colWidth: previousColWidth,
            previousColWidth,
            inputs,
            outputs,
            layoutConfig: this.layoutConfig,
          }),
        });
        previousColWidth = 0;
        previousRowHeight = maxRowHeight;
        rowStartX = 0;
        colStartX = 0;
        y = rowRect.y + maxRowHeight + GRID_ROW_GAP;
      }
      if (isEmptyBlockGrid) {
        y = sectionLayoutInfos[k].rect.y;
      }

      // boundary after the last row in a section
      boundaries.push({
        sectionIndex: k,
        orientation: 'horizontal',
        boundaryKey: `h.${k}.0.${blockGrid.length}`,
        x: rowStartX,
        y: isEmptyBlockGrid ? y : y - GRID_ROW_GAP + BOUNDARY_VERTICAL_OFFSET,
        width: gridWidth,
        height: BOUNDARY_THICKNESS,
        insertionRow: blockGrid.length,
        insertionCol: 0,
        isFirstInRow: false,
        isLastInRow: false,
        isFirstInSection: blockGrid.length === 0,
        isLastInSection: true,
        dragPolygon: getDragPolygonHorizontal({
          isFirst: blockGrid.length === 0,
          isLast: true,
          isFirstSection: k === 0,
          isLastSection: k === this.sectionGrids.length - 1,
          rowY: y,
          previousRowHeight,
          rowHeight: previousRowHeight,
          inputs,
          outputs,
          layoutConfig: this.layoutConfig,
        }),
      });
    }
    const lastSectionRect =
      sectionLayoutInfos.length > 0 ? sectionLayoutInfos[sectionLayoutInfos.length - 1].rect : null;
    // boundary after the last section
    boundaries.push({
      sectionIndex: this.sectionGrids.length,
      boundaryKey: `h.${this.sectionGrids.length}.0.0`,
      orientation: 'horizontal',
      x: 0,
      y: lastSectionRect ? lastSectionRect.y + lastSectionRect.height + 2 * (SECTION_GAP / 2) : 0,
      width: gridWidth,
      height: BOUNDARY_THICKNESS,
      insertionRow: 0,
      insertionCol: 0,
      isFirstInRow: false,
      isLastInRow: false,
      isFirstInSection: true,
      isLastInSection: false,
      dragPolygon: null,
      forNewSection: true,
    });

    // console.log('lm _updateBoundaries', boundaries);
    this.boundaries = boundaries;
  }
}
